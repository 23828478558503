import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import APP_CONFIGS from '../../configs/app'
import queryStrings from 'wsc/utils/queryStrings'

const LocaleStateContext = React.createContext(null)

const LocaleProvider = props => {
  const [locale, setLocale] = useState(null)

  // Use useEffect to setLocale by queryStrings['language'] only when first page is loaded
  // to prevent graphQl problem when value of queryStrings['language'] is not equal to the local storage
  useEffect(() => {
    setLocale(defaultLocale())
    localStorage.setItem('locale', defaultLocale())
  }, [])

  const defaultLocale = () => {
    return queryStrings['language'] || localStorage.getItem('locale') || APP_CONFIGS.language
  }

  return (
    <LocaleStateContext.Provider value={{ locale, setLocale }}>
      {props.children}
    </LocaleStateContext.Provider>
  )
}

LocaleProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { LocaleProvider, LocaleStateContext }
