import en from './en.json'
import es from './es.json'
import { setConfig, getConfig } from 'wsc/globalConfig'

const translations = {
  en,
  es,
}

setConfig('Translations', translations)
export default getConfig('Translations')
