const APP_CONFIGS = {
  // process.env.DEPLOY_PRIME_URL is a built-in environment variable from netlify
  url:
    process.env.BRANCH && process.env.BRANCH !== 'master'
      ? process.env.DEPLOY_PRIME_URL
      : 'https://mamaslatinas.com',
  name: 'MamasLatinas.com',
  title: 'Parenting, Entertainment, Lifestyle for Latinas',
  logo: '/images/logo/ML-Icon.png',
  description:
    'MamásLatinas covers what bilingual and bicultural Latinas care about - including parenting, entertainment, beauty & style, health, love, food & more.',
  facebookAppId: '225178007564787',
  // Default language
  language: 'es',
  envUrl: process.browser ? window.location.protocol + '//' + window.location.host : null,
  // Available locales
  locales: {
    en: {
      title: 'English',
    },
    es: {
      title: 'Spanish',
    },
  },
  // Max number of posts to load via infinite scroll
  maxInfiniteScrollPosts: 3,
  // Number of pixels to look ahead of scroll to preload next article
  infiniteScrollLookahead: 2000,
  languageList: ['es', 'en'],
  prop30SiteName: 'quemas',
  noSkimlinksForTheseCategorySlugs: [],
  ctaBannerData: {
    imageNames: {
      desktop1x: 'momlife_cta_desktop.jpg',
      desktop2x: 'momlife_cta_2x_desktop.jpg',
      mobile1x: 'momlife_cta_mobile.jpg',
      mobile2x: 'momlife_cta_2x_mobile.jpg',
    },
    altText: 'Talk to moms about mom stuff. And make money doing it.',
    buttonUrl: `https://cafemom.com/momlife?utm_source=MamasLatinas.com&utm_medium=site_banner`,
    styles: `
    margin-bottom: 3.125rem
  `,
  },
}

module.exports = APP_CONFIGS
