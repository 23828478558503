import { setConfig, getConfig } from 'wsc/globalConfig'

const styleConfig = {
  COLORS: {
    // ML COLORS BUILDS
    ML_PURPLE: '#883D6B',
    ML_PURPLE_08: '#883D6B14',
    ML_GREEN: '#1E6770',
    ML_LIGHT_GREEN: '#A5C2C6',
    ML_ORANGE: '#FAAD7D',
    ML_LIGHT_ORANGE: '#FCCDB0',
    ML_RED: '#A51E22',
    ML_CREAM: '#EAE6DF',
    ML_GRAY: '#676767',
    ML_LIGHT_GRAY: '#E5E5E5',
    ML_VERY_LIGHT_GRAY: '#E7E7E7',
    ML_LIGHT_PURPLE: '#47397A',
    ML_DARK_PURPLE: '#713359',
    ML_DARK_GREEN: '#154A51',
    ML_SHARE_FB_BG: '#39579A',
    ML_SHARE_FB_HOVER_BG: '#2C4477',
    ML_SHARE_FB_ACTIVE_BG: '#4266B3',
    ML_DARK_GREY: '#A7A7A7',
    ML_PINK: '#EB1E4F',
    ML_DARK_PINK: '#C4204E',
    ML_LIGHT_PINK: '#FEEFF0',
    ML_YELLOW: '#F3A31E',
    ML_CHASE_DARK_PURPLE: '#873D6C',
    ML_CHASE_FONT_PURPLE: '#7E416A',
    ML_CHASE_PURPLE: '#C49EB5',
    ML_CHASE_YELLOW: '#E6A542',
    ML_CHASE_PINK: '#E38484',
    ML_CHASE_BLUE: '#453B76',
    ML_POPUP_HOVER_GREEN: '#E7D8E2',
    ML_POPUP_ACTIVE_GREEN: '#CFB1C4',
    //Overrides
    FOCUS: '#718bd1',
    VALID: '#278591',
    ERROR: '#CF262C',
  },
  FONT_FAMILIES: {
    SERIF: `'Bitter', serif`,
    SANSSERIF: `'Open Sans', sans-serif`,
    JWPLAYER: `'JW Player Font Icons'`,
  },
}

setConfig('StyleConfig', styleConfig)

export default getConfig('StyleConfig')
