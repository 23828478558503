import { createGlobalStyle } from 'styled-components/macro'
import defaultGlobalStyle from 'wsc/defaultGlobalStyle'
import { COLORS, FONT_FAMILIES, MEDIA } from '../../utils/styles'

const GlobalStyle = createGlobalStyle`
  ${defaultGlobalStyle}  
  * {
    box-sizing: border-box;
    word-break: break-word;
    margin: 0;
    padding: 0;
  }

  html {
    -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
  }

  body {
    /** Ad callouts */
    --adCalloutFontFamily: 'OpenSans', sans-serif;
    --adCalloutFontSize: 12px;

    /** Social Button */
    --socialButton_CircleColor: ${COLORS.ML_PURPLE};
    --socialButton_CircleHoverColor: ${COLORS.ML_DARK_PURPLE};
    --socialButton_CircleActiveColor: ${COLORS.ML_ORANGE};
    --socialButton_NoBgColor: ${COLORS.WHITE};
    --socialButton_NoBgHoverColor: ${COLORS.ML_ORANGE};
    --socialButton_NoBgActiveColor: ${COLORS.ML_ORANGE};

    /** inputBoxDefault */
    --inputBoxDefault_borderColor: ${COLORS.ML_VERY_LIGHT_GRAY};
    --inputBoxValid_borderColor: ${COLORS.VALID};
    --inputBoxFocus_borderColor: ${COLORS.ML_VERY_LIGHT_GRAY};
    --inputBoxError_color: ${COLORS.ERROR};

    /** MUI */
    --checkbox_Color: ${COLORS.ML_PURPLE};
    --selectedListItem_BgColor: ${COLORS.ML_PURPLE_08};
    --chip_BgColor: ${COLORS.ML_LIGHT_GREEN};
    --touchRipple_BgColor: ${COLORS.ML_PURPLE};

    /** defaultPlaceholder */
    --defaultPlaceholder_color: ${COLORS.ML_DARK_GREY};
    
    /** withImageBoxShadow */
    --withImageBoxShadow_boxShadowColor: ${COLORS.ML_ORANGE};

    /** withButton */
    --withButton_BackgroundColor: ${COLORS.ML_PURPLE};
    --withButton_HoverBackgroundColor: ${COLORS.ML_DARK_PURPLE};
    --withButton_ActiveBackgroundColor: ${COLORS.ML_ORANGE};

    /** Link */
    --defaultLinkStyle_Color: ${COLORS.BLACK};
    --defaultLinkStyle_HoverColor: ${COLORS.ML_PURPLE};
    --defaultLinkStyle_BorderBottomColor: ${COLORS.ML_PURPLE};

    /* ContentMarkdown */
    --contentMarkdown_figureCaption_color: ${COLORS.ML_GRAY};
    --contentMarkdown_figureContainer_linkBackgroundColor: ${COLORS.ML_PURPLE};
    --contentMarkdown_figureContainer_linkHoverBefore_opacity: 0.6;
    --contentMarkdown_startQuoteContainer_display: none;
    --contentMarkdown_endQuoteContainer_display: none;
    --contentMarkdown_iconTwitterFillColor: ${COLORS.ML_PURPLE};
    --contentMarkdown_quoteShareText_borderBottom: 0.125rem solid ${COLORS.ML_PURPLE};
  }

  body, .font-body, .font-body-serif {
    word-break: break-word;
    color: ${COLORS.BLACK};
    font-size: 1.125rem;
    line-height: 1.7;

    ${MEDIA.TABLET`font-size: 1.1875rem;`}
    ${MEDIA.DESKTOP`font-size: 1.25rem;`}
  }

  body, .font-body {
    font-family: ${FONT_FAMILIES.SANSSERIF};
  }

  .font-body-serif {
    font-family: ${FONT_FAMILIES.SERIF};
  }

  h1, h2, h3, h4, .font-h1, .font-h2, .font-h3, .font-h4 {
    font-family: ${FONT_FAMILIES.SANSSERIF};
    color: ${COLORS.BLACK};
  }

  h1, .font-h1 {
    font-weight: bold;
    font-size: 1.60rem;
    line-height: 1.33;

    ${MEDIA.TABLET`font-size: 2.32rem;`}
    ${MEDIA.DESKTOP`font-size: 2.96rem;`}
  }

  h2, .font-h2 {
    font-weight: bold;
    font-size: 1.42rem;
    line-height: 1.33;

    ${MEDIA.TABLET`font-size: 1.855rem;`}
    ${MEDIA.DESKTOP`font-size: 2.22rem;`}
  }

  h3, .font-h3 {
    font-weight: 600;
    font-size: 1.265rem;
    line-height: 1.6;

    ${MEDIA.TABLET`font-size: 1.48rem;`}
    ${MEDIA.DESKTOP`font-size: 1.666rem;`}
  }

  h4, .font-h4 {
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 1.7;

    ${MEDIA.TABLET`font-size: 1.187rem;`}
    ${MEDIA.DESKTOP`font-size: 1.25rem;`}
  }

  blockquote {
    font-family: ${FONT_FAMILIES.SERIF};
    font-size: 1.66875rem;
    font-style: italic;
    color: ${COLORS.BLACK};
    line-height: 1.6;

    ${MEDIA.TABLET`font-size: 1.4875rem;`}
    ${MEDIA.MOBILE`font-size: 1.26875rem;`}
  }

  hr {
    border: none;
    height: 0.0625rem;
    background-color: ${COLORS.ML_VERY_LIGHT_GRAY};
  }

  .font-small-body {
    font-size: 1rem;
    line-height: 1.7;
    font-family: ${FONT_FAMILIES.SANSSERIF};

    ${MEDIA.TABLET`font-size: 0.95rem`}
    ${MEDIA.DESKTOP`font-size: 0.9375rem`}
  }

  .font-description {
    /* font size and line-height style based on 'Description' style in style guide */
    font-family: ${FONT_FAMILIES.SANSSERIF};
    font-size: 0.79rem;
    line-height: 1.7;

    ${MEDIA.TABLET`font-size: 0.76rem;`}
    ${MEDIA.DESKTOP`font-size: 0.704rem;`}
  }

  .font-section-header-1 {
    font-family: ${FONT_FAMILIES.SERIF};
    font-weight: normal;
    font-size: 1.42rem;
    line-height: 1.33;

    ${MEDIA.TABLET`font-size: 1.856rem;`}
    ${MEDIA.DESKTOP`font-size: 2.22rem;`}
  }

  .font-section-header-2 {
    font-family: ${FONT_FAMILIES.SERIF};
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.7;

    ${MEDIA.TABLET`font-size: 1.187rem;`}
    ${MEDIA.DESKTOP`font-size: 1.25rem;`}
  }


  /* TODO: Fix these fonts according to updated style-guide */
  .font-button-title {
    font-size: 1.8em;
  }

  .font-button-subtitle {
    font-weight: bold;
    font-size: 0.86rem;

    ${MEDIA.TABLET`font-size: 0.706rem;`}
    ${MEDIA.DESKTOP`font-size: 0.789rem;`}
  }

  .font-button {
    font-family: ${FONT_FAMILIES.SANSSERIF};
    line-height: 1.59;
    font-size: 0.94rem;
  }

  /* -------------------------------------------------------- */
  /* Below are a custom CSS className used for override a global style of all needed components
  /* -------------------------------------------------------- */

  .h4-parenting-title {
    ${MEDIA.DESKTOP`
    font-size: 1rem;
    line-height: 1.38;
    svg {
      width: 13px; 
      height: 13px;
    }`}
  }

  .suggested-title {
    ${MEDIA.DESKTOP`font-size: 0.9375rem;`}
    ${MEDIA.TABLET`font-size: 0.95rem;`}
    ${MEDIA.MOBILE`font-size: 1rem;`}
    font-weight: bold;
    font-family: ${FONT_FAMILIES.SANSSERIF};
    line-height: 1.7;
  }

  /* --------------------------------------------------------- */
  /* Overrides for wsc defaults */
  /* --------------------------------------------------------- */
  
  .withButtonOutlined {
    --withButton_Color: ${COLORS.ML_PURPLE};
    --withButton_Border: 1px solid ${COLORS.ML_PURPLE};
    --withButton_SvgFill: ${COLORS.ML_PURPLE};
        
    --withButton_HoverBackgroundColor: ${COLORS.ML_DARK_PURPLE};
    --withButton_HoverBorder: 1px solid ${COLORS.ML_DARK_PURPLE};
    
    --withButton_ActiveBackgroundColor: ${COLORS.ML_ORANGE};
    --withButton_ActiveBorder: 1px solid ${COLORS.ML_ORANGE};
  }

  .ShopButton {
    --withButton_BackgroundColor: ${COLORS.ML_GREEN};
    --withButton_HoverBackgroundColor: ${COLORS.ML_DARK_GREEN};
  }
  
  .SuggestedPosts {
    --withImageBoxShadow_boxShadowColor: ${COLORS.ML_PURPLE};
  }

  .AuthorHeader {
    .AuthorImage {
      --withImageBoxShadow_boxShadowLength: 5px;
      ${MEDIA.TABLET`--withImageBoxShadow_boxShadowLength: 8px;`}
      ${MEDIA.DESKTOP`--withImageBoxShadow_boxShadowLength: 10px;`}
    }
  }

  .WebinarButton {
    --withButton_Color: ${COLORS.WHITE};
    --withButton_BackgroundColor: ${COLORS.ML_PINK};
    --withButton_HoverBackgroundColor: ${COLORS.ML_DARK_PINK};
    --withButton_ActiveBackgroundColor: ${COLORS.ML_LIGHT_PURPLE};
  }

  .ChaseButton {
    --withButton_Color: ${COLORS.ML_CHASE_DARK_PURPLE};
    --withButton_BackgroundColor: ${COLORS.ML_CHASE_YELLOW};
    --withButton_HoverColor: ${COLORS.WHITE};
    --withButton_HoverBackgroundColor: ${COLORS.ML_CHASE_DARK_PURPLE};
    --withButton_ActiveColor: ${COLORS.WHITE};
    --withButton_ActiveBackgroundColor: ${COLORS.ML_CHASE_PINK};
  }

  /** Button Action */
  .buttonAction {
    --buttonAction_Height: 50px;
    --buttonAction_FontFamily: ${FONT_FAMILIES.SANSSERIF};
    --buttonAction_Disable_BackgroundColor: ${COLORS.ML_CREAM};
    --buttonAction_Disable_FontColor: ${COLORS.ML_GRAY};
  }
`

export default GlobalStyle
